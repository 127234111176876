import { FC } from 'react';

import { TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

import Invoice from 'types/Invoices';

type Props = {
  totalHoursPerInvoice: Invoice.TotalHoursPerInvoice[];
  totalHoursStats: Invoice.TotalHoursStats;
};

const InvoiceTotalHoursTable: FC<Props> = ({ totalHoursPerInvoice, totalHoursStats }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="right"></TableCell>
            <TableCell align="right">Total</TableCell>
            <TableCell align="right">Regular</TableCell>
            <TableCell align="right">Overtime</TableCell>
            <TableCell align="right">Total Billable</TableCell>
            <TableCell align="right">Holiday</TableCell>
            <TableCell align="right">Emergency</TableCell>
            <TableCell align="right">Invoice ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {totalHoursPerInvoice.length > 1 &&
            totalHoursPerInvoice.map((row) => (
              <TableRow key={row.invoice_id}>
                <TableCell align="right">Hours:</TableCell>
                <TableCell align="right">{row.total_hours}</TableCell>
                <TableCell align="right">{row.regular_hours}</TableCell>
                <TableCell align="right">{row.overtime_hours}</TableCell>
                <TableCell align="right">{row.total_billable_hours}</TableCell>
                <TableCell align="right">{row.holiday_hours}</TableCell>
                <TableCell align="right">{row.emergency_hours}</TableCell>
                <TableCell align="right">{row.invoice_id}</TableCell>
              </TableRow>
            ))}
          <TableRow>
            <TableCell align="right">Total Hours:</TableCell>
            <TableCell align="right">{totalHoursStats.total_hours}</TableCell>
            <TableCell align="right">{totalHoursStats.regular_hours}</TableCell>
            <TableCell align="right">{totalHoursStats.overtime_hours}</TableCell>
            <TableCell align="right">{totalHoursStats.total_billable_hours}</TableCell>
            <TableCell align="right">{totalHoursStats.holiday_hours}</TableCell>
            <TableCell align="right">{totalHoursStats.emergency_hours}</TableCell>
            <TableCell align="right">
              {totalHoursPerInvoice.length === 1 ? totalHoursPerInvoice[0]?.invoice_id : ''}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoiceTotalHoursTable;
