import Invoice from 'types/Invoices';

import ApiService, { API } from 'Services/HttpService';

class InvoicesAPI {
  getAllConfigs = async (params: API.RequestBody) => {
    return await ApiService.GET('/invoices/configs', params);
  };

  getConfig = async (configId: string | number) => {
    return await ApiService.GET(`/invoices/configs/${configId}`);
  };

  getTemplates = async () => {
    return await ApiService.GET('/invoices/templates');
  };

  createConfig = async (configData: API.RequestBody) => {
    return await ApiService.POST_JSON(`/invoices/configs`, configData);
  };

  updateConfig = async (configId: string | number, configUpdate: API.RequestBody) => {
    return await ApiService.PUT_JSON(`/invoices/configs/${configId}`, configUpdate);
  };

  deleteConfig = async (configId: string | number) => {
    return await ApiService.DELETE(`/invoices/configs/${configId}`);
  };

  getInvoices = async (params: Invoice.GetInvoicesParams) => {
    return await ApiService.GET<Invoice.GetInvoicesResponse>('/invoices', params);
  };

  getConfigInvoices = async (configId: string | number, params: API.RequestBody) => {
    return await ApiService.GET<Invoice.GetInvoicesResponse>(`/invoices/configs/${configId}/invoices`, params);
  };

  getInvoice = async (configId: string | number, invoiceId: string | number, params: API.RequestBody) => {
    return await ApiService.GET(`/invoices/configs/${configId}/invoices/${invoiceId}`, params);
  };

  getInvoiceTimesheets = async (configId: string | number, invoiceId: string | number, params: API.RequestBody) => {
    return await ApiService.GET(`/invoices/configs/${configId}/invoices/${invoiceId}/timesheets`, params);
  };

  getTimesheetsTotalHours = async (timesheets: { timesheetIds: string | number }) => {
    return await ApiService.GET<Invoice.GetTimesheetsTotalHoursResponse>(`/timesheets/calculate-sum-hours`, timesheets);
  };

  markTimesheetsAsPaid = async (timesheets: API.RequestBody) => {
    return await ApiService.PUT(`/timesheets/mark-as-paid`, timesheets);
  };

  generateConfigInvoice = async (
    configId: string | number,
    timeInterval?: { from_datetime: string; to_datetime: string }
  ) => {
    return await ApiService.POST_JSON(`/invoices/configs/${configId}/invoices/generate`, timeInterval);
  };

  revertInvoice = async (configId: string | number, invoiceId: string | number) => {
    return await ApiService.POST(`/invoices/configs/${configId}/invoices/${invoiceId}/revert`);
  };

  updateInvoiceStatus = async (configId: string | number, invoiceId: string | number, status: string) => {
    return await ApiService.POST(`/invoices/configs/${configId}/invoices/${invoiceId}/status`, { status });
  };

  assignTimesheetsToInvoice = async (configId: string | number, invoiceId: string | number) => {
    return await ApiService.POST(`/invoices/configs/${configId}/invoices/${invoiceId}/assign-more-timesheets`);
  };

  downloadInvoice = async (configId: string | number, invoiceId: string | number) => {
    return await ApiService.DOWNLOAD_FILE(`/invoices/configs/${configId}/invoices/${invoiceId}/download`);
  };

  downloadPOs = async (configId: string | number, invoiceId: string | number) => {
    return await ApiService.DOWNLOAD_FILE(`/invoices/configs/${configId}/invoices/${invoiceId}/download-pos`);
  };

  downloadNonPaidTimesheets = async (
    configId: string | number,
    params: {
      po?: string | number;
      conf?: string | number;
    }
  ) => {
    return await ApiService.DOWNLOAD_FILE(`/invoices/configs/${configId}/download-non-paid-timesheets`, params);
  };

  updateInvoiceDate = async (
    configId: string | number,
    invoiceId: string | number,
    timeInterval: { from_datetime: string; to_datetime: string }
  ) => {
    return await ApiService.POST(`/invoices/configs/${configId}/invoices/${invoiceId}/update-dates`, timeInterval);
  };

  removeUnpaidTimesheet = async (
    configId: string | number,
    invoiceId: string | number,
    timesheetId: string | number
  ) => {
    return await ApiService.POST(
      `/invoices/configs/${configId}/invoices/${invoiceId}/timesheets/${timesheetId}/remove`
    );
  };

  downloadInvoiceTimesheets = async (timesheet_ids: string | number | string[] | number[]) => {
    const ids = Array.isArray(timesheet_ids) ? timesheet_ids.join(',') : timesheet_ids.toString().trim();
    return await ApiService.DOWNLOAD_FILE(`/invoices/download-timesheets`, { timesheet_ids: ids });
  };
}

export default new InvoicesAPI();
